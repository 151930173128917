import React from 'react';
import { object } from 'prop-types';
import loadable from '@loadable/component';
import queryString from 'query-string';

import useCanShareReward from 'hooks/use-can-share-reward';

import { SkeletonHomeAdvertisingView } from 'views/home/skeleton';
import { SkeletonAccountPage } from 'views/account/skeleton';

import { useNavigationContext } from 'contexts/navigation-context';
import { isAuthenticated } from 'services/authentication-service';

const LazyStampPage = loadable(() => import('suspended-page/loyalty-card/loyalty/update/stamp'));

const StampLoyaltyPage = ({ location = {} }) => {
  const { search = '' } = location;
  const { passId = '' } = queryString.parse(search);

  const { canReward } = useCanShareReward();

  const {
    homePageOnly = () => {},
    gotoAccount = () => {},
  } = useNavigationContext();
  const authenticated = isAuthenticated();

  if (!authenticated) {
    homePageOnly();
    return (<SkeletonHomeAdvertisingView />);
  }

  if (!canReward) {
    gotoAccount();
    return (<SkeletonAccountPage />);
  }

  return (<LazyStampPage passId={passId}/>);
};

StampLoyaltyPage.propTypes = {
  location: object,
};

export default StampLoyaltyPage;
